<template>
  <div class="page">
    <van-row>
      <van-col span="3" @click="back()"
        ><div class="back-btn"><van-icon name="arrow-left" />返回</div></van-col
      >
      <van-col span="21">
        <van-search
          v-model="keyword"
          placeholder="请输入搜索关键词"
          ref="keyword"
        ></van-search>
      </van-col>
    </van-row>
    <van-row class="title">
      <van-col span="24">客户列表</van-col>
    </van-row>
    <div class="person" v-for="person in personList" :key="person.code">
      <van-row class="base">
        <van-col span="5"
          ><van-image width="55" height="80" :src="person.photoPath"></van-image
        ></van-col>
        <van-col span="19">
          <van-row>
            <van-col span="12" class="text">姓名：{{ person.name }}</van-col>
            <van-col span="12" class="text">年龄：{{ person.age }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12" class="text"
              >性别：{{ person.genderName }}</van-col
            >
            <van-col span="12" class="text"
              >民族：{{ person.nationName }}</van-col
            >
          </van-row>

          <van-row>
            <van-col span="24" class="text"
              >更新时间：{{ person.updateTime }}</van-col
            >
          </van-row>
          <van-row>
            <van-col span="12">
              <van-button
                class="btn"
                icon="plus"
                :color="COLOR_M"
                size="mini"
                @click="personView(person.code)"
                >预览下载</van-button
              >
            </van-col>
            <van-col span="12">
              <van-button
                class="btn"
                icon="aim"
                :color="COLOR_S1"
                size="mini"
                @click="personMath(person.code)"
                >智能匹配</van-button
              >
            </van-col>
          </van-row>
        </van-col>
      </van-row>
      <van-row class="comment">
        <van-col span="20" class="text">
          <van-field
            label-width="50"
            v-model="person.comment"
            name="备注"
            label="备注"
            rows="1"
            autosize
            type="textarea"
            maxlength="20"
            placeholder="请输入20个字以内的备注信息"
        /></van-col>
        <van-col span="4" class="btn">
          <van-button
            icon="edit"
            :color="COLOR_M"
            size="mini"
            @click="
              updateComment(person.code, person.refereeCode, person.comment)
            "
            >修改</van-button
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="no-record" v-if="personList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <div class="operate">
      <van-row class="business">
        <van-col span="24">
          <van-field
            v-model="profileText"
            readonly
            clickable
            name="资料模板"
            label="资料模板"
            placeholder="点击选择资料模板"
            @click="profileShow = true"
          />
          <van-popup :show="profileShow" position="bottom">
            <van-picker
              :columns="profileTree"
              @confirm="confirmProfile"
              @cancel="profileShow = false"
            />
          </van-popup>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="plus"
            :color="COLOR_M"
            size="small"
            @click="personInviter()"
            >立即邀请</van-button
          >
          <van-button
            class="btn"
            icon="setting-o"
            :color="COLOR_S1"
            size="small"
            @click="personCusInviter()"
            >自定义邀请</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import { Search, Image, Popup, Icon, Picker, Field, Tag } from 'vant'
export default {
  components: {
    Share: Share,
    Loading: Loading,
    [Image.name]: Image,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Field.name]: Field,
    [Tag.name]: Tag
  },
  data () {
    return {
      shareShow: false,
      loadingShow: false,
      profileShow: false,
      profileText: '工作通用',
      profileCode: '1',
      profileKey: 'RECRUIT_NORMAL',
      profileTree: [],
      personList: []
    }
  },
  mounted () {
    document.title = '成员邀请'
    this.retrievePersonList()
    this.retrieveProfileTree()
    this.initShare()
  },
  methods: {
    confirmProfile (obj) {
      this.profileText = obj[1].text
      this.profileCode = obj[1].code
      this.profileKey = obj[1].key
      this.profileShow = false
      this.initShare()
    },
    async retrievePersonList () {
      this.loadingShow = true
      var sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: sellerCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrieveSellerPersonList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.personList = res.data
      }
      this.loadingShow = false
    },
    async retrieveProfileTree () {
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/profile/retrieveProfileTree')
      if (res.status === '200') {
        this.profileTree = res.data
      }
    },
    personInviter () {
      this.shareShow = true
    },
    personCusInviter () {
      this.$router.push({ path: '/seller/personInviter', query: { profile: this.profileKey } })
    },
    personMath (code) {
      var url = window.location.href
      window.sessionStorage.setItem(window.const.global.PEOPLE_PERSON_MATCH_BACK, url)
      this.$router.push({ path: '/people/match', query: { personCode: code } })
    },
    personView (code) {
      var url = window.location.href
      window.sessionStorage.setItem(window.const.global.PEOPLE_PERSON_VIEW_BACK, url)
      this.$router.push({ path: '/people/person/view', query: { personCode: code } })
    },
    async updateComment (personCode, refereeCode, comment) {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var pd = { sellerCode: seller, personCode: personCode, refereeCode: refereeCode, comment: comment }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/updatePersonComment', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$dialog.alert({
          title: '提示信息',
          message: '修改备注成功'
        })
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '修改备注失败'
        })
      }
    },
    initShare () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var title = '个人电子资料'
      var desc = '完善您的个人电子资料，便于工作、证书、上学等咨询服务'
      var link = 'https://moc.utopanet.com/member/person?profile=' + this.profileKey + '&referee=' + user
      if (seller !== undefined && seller !== null && seller !== '') {
        link = link + '&seller=' + seller
      }
      if (operator !== undefined && operator !== null && operator !== '') {
        link = link + '&operator=' + operator
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.init(title, desc, link, logo)
    },
    back () {
      var url = window.sessionStorage.getItem(window.const.global.SELLER_PERSON_INDEX_BACK)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  padding-bottom: 120px;
}
.back-btn {
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}
.title {
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
  color: #fff;
  background: -webkit-gradient(linear, 0 0, 100% 0, from(#00cc99), to(#fff));
}
.person {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  .base {
    padding: 5px 0px;
    .text {
      text-align: left;
      padding-left: 10px;
      height: 20px;
      line-height: 20px;
    }
    .btn {
      width: 80%;
    }
    border-bottom: 1px dashed #eee;
  }
  .comment {
    padding: 5px 0px;
    .text {
      padding-left: 10px;
      text-align: left;
    }
    .btn {
      margin-top: 10px;
    }
  }
}

.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 100px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
  .business {
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
  .btn {
    margin-left: 5px;
  }
}
.shareImg {
  width: 90%;
}
</style>
